import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    req_get_display_name_lookup,
    req_get_verify_otp,
    req_login_refresh,
    req_user_register
} from "../../helpers/requests";
import {useState} from "react";
import {setLocalStorageVals} from "../../helpers/settings";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import PrivacyPolicy from "../content/privacyPolicy";
import TermsOfService from "../content/termsOfService";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { MuiOtpInput } from 'mui-one-time-password-input';
//import ReactGA from "react-ga4";
import {IconButton, InputAdornment, Tooltip} from "@mui/material";
import DialogAppBar from "../dialogs/dialogAppBar";
import Divider from "@mui/material/Divider";
import {auth} from "../../helpers/firebase";
import {content} from "../../globals/content";
import { useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
//ReactGA.initialize(process.env.REACT_APP_GA_ID1,[]);


const theme = createTheme();

export default function Register() {

    const [handleValue, setHandleValue] = React.useState("");
    const [handleError, setHandleError] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showSocialError, setShowSocialError] = React.useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
    const [termsOfServiceOpen, setTermsOfServiceOpen] = React.useState(false);
    const [registrationComplete, setRegistrationComplete] = React.useState(false);
    const [docsApproved, setDocsApproved] = React.useState(true);
    const [socialDocsChecked, setSocialDocsChecked] = React.useState(true);
    const [docsError, showDocsError] = React.useState(false);
    const [otp, setOtp] = React.useState('');


    const [displayNameValue, setDisplayNameValue] = React.useState("");
    const [displayNameError, setDisplayNameError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [passwordError, setPasswordError] = React.useState(false);
    const provider = new GoogleAuthProvider();
    const [searchParams] = useSearchParams();
    const [showPassword, setShowPassword] = React.useState(false);
    const [showReset, setShowReset] = React.useState(false);
    const [tempId, setTempId] = React.useState("");

    const handleSubmit = (event) => {
        setShowReset(false);
        event.preventDefault();
        clearErrors();
        const data = new FormData(event.currentTarget);

        if (registrationComplete) {
            validateAccount();

        } else {


            if (data.get('password') === "" || data.get('displayName') === "" ||
                data.get('email') === "" || !docsApproved || displayNameError) {

                setLoading(false);
                setEmailError(data.get("email") === "");
                setPasswordError(data.get("password") === "");
                setDisplayNameError(displayNameValue === "");
                showDocsError(!docsApproved);
                setShowError(true);


            } else {

                console.log("handleError", handleError)

                if (!handleError) {
                    setShowError(false);
                    setLoading(true);

                    req_user_register(data.get('email'), data.get('password'), data.get('displayName'), data.get('handle'), true, localStorage.getItem("siteLanguage"))
                        .then(function (result) {
                            console.log("in then", result)


                            if (result.status === 200) {
                                /*
                                ReactGA.event({
                                    category: "login events",
                                    action: "registration_success",
                                    label: "in app"
                                });

                                */

                                //result.data.data.refreshToken
                                localStorage.setItem("rtoken", result.data.data.tempToken);
                                setTempId(result.data.data.id);
                                setRegistrationComplete(true);
                                setLoading(false)

                            } else {

                                if (result?.response?.data?.error?.code) {
                                    if (result.response.data.error.code === 400) {
                                        console.log("myerror", result)
                                        console.log("400", result.response.data.error.code)
                                        console.log("400", result.response.data.error.message)

                                        setErrorMessage(content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"][result.response.data.error.message]);
                                        setShowError(true);
                                        setShowReset(true);
                                        setLoading(false);
                                    }
                                }
                            }


                        }).catch((error) => {
                        console.log("in catch")


                        console.log(error)
                        console.log(error.data)
                        console.log(error.response)


                    });

                    console.log("other")

                } else {

                    console.log("else")
                    setShowError(true);
                    setLoading(false)
                }
            }
        }
    }

    const handleOtpChange = (newValue) => {
        setOtp(newValue)
    }

    const clearErrors = () => {
        setDisplayNameError(false);
        setEmailError(false);
        setPasswordError(false);
        setShowError(false);
        setShowSocialError(false);
        setHandleError(false);
    }


    const handlePPClose = () => { setPrivacyPolicyOpen(false) }

    const handleTOSClose = () => { setTermsOfServiceOpen(false) }

    const handleFacebookRegister = () => {

    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }


    const handleGoogleRegister = () => {
        clearErrors();

        if (socialDocsChecked){
            setShowSocialError(false);
            signInWithPopup(auth, provider).then((userCredential) => {
                setLoading(true);
                const token = userCredential.user.stsTokenManager.refreshToken;

                let body = {
                    email: userCredential.user.email,
                    emailVerified: userCredential.user.emailVerified,
                    isSocial: true,
                    socialType: "google",
                    docsChecked: true,
                    isRegistration: true
                }

                req_login_refresh(token, body).then(function (result) {

                    if (result.status === 200) {
                        setLocalStorageVals(result);

                        if (searchParams.get('redirect')) {
                            window.location = searchParams.get('redirect');
                        } else {
                            window.location = '/';
                        }

                        setLoading(false)
                    } else {
                        setLoading(false);
                        setShowError(true);
                        setTimeout(() => setShowError(false), 5000);
                    }
                })

            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);

            });
        } else {
            setShowSocialError(true);
        }
    }


    /*
    const confirmAvailability = (handle) => {
        req_get_handle_lookup(handle).then(function (result) {
            if (result.status === 200) {
                if (result.data.inUse > 0){
                    setHandleError(true);
                } else {
                    setHandleError(false);
                }
            }
        })
    }

    */

    const validateAccount = (event) => {
        console.log("test")
        event.preventDefault();
        setShowError(false);
        setLoading(true);

        req_get_verify_otp(tempId, otp)
            .then(function (result) {

                if (result.status === 200 && result.data.verified) {
                    console.log(result)
                    console.log(result.data.verified)
                    /*
                    ReactGA.event({
                        category: "login events",
                        action: "registration_confirmation_success",
                        label: "in app"
                    });

                    */
                    setRegistrationComplete(true);
                    setShowError(false);


                    let body = {email: result.data.email, isSocial: false}
                    req_login_refresh(localStorage.getItem("rtoken"), body).then(function (result2) {

                        if (result2.status === 200) {
                            setLocalStorageVals(result2);
                            console.log(searchParams)
                            console.log(searchParams.get('redirect'))
                            if (searchParams.get('redirect')) {
                                window.location = searchParams.get('redirect');

                            } else {
                                window.location = '/';
                            }
                            setLoading(false)
                        } else {
                            setLoading(false);
                            setShowError(true);
                            setErrorMessage("The provided one-time password was incorrect or expired. Please try again.");

                            setTimeout(() => setShowError(false), 5000);
                        }
                    })





                } else {
                    console.log("else verify")
                    setShowError(true);
                    setErrorMessage("The provided one-time password was incorrect or expired. Please try again.");
                    setLoading(false)
                }
            })

    }

    const confirmAvailability = (handle) => {
        req_get_display_name_lookup(handle).then(function (result) {
            if (result.status === 200) {
                if (result.data.inUse > 0){
                    setDisplayNameError(true);
                } else {
                    setDisplayNameError(false);
                }
            }
        })
    }


    console.log(searchParams)
    console.log(searchParams.get('redirect'))

    return (
        <ThemeProvider theme={theme}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {(showError) ?
                <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>{errorMessage}
                    {showReset ?
                        <IconButton>
                            <LockResetIcon onClick={
                                () => {
                                    if (searchParams.get('redirect')) {
                                        window.location = "/resetpass?redirect=" + searchParams.get('redirect');

                                    } else {
                                        window.location = '/resetpass';
                                    }
                                }
                            } />
                        </IconButton>
                        : null
                    }
                </Alert> : null
            }
            {(showSocialError) ?
                <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].socialRegistrationError}</Alert> : null
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: '#FF2F00' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <div style={{width: "100%", display: registrationComplete ? "none" : "block"}}>

                        <Typography component="h1" variant="h5">
                            {content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                        </Typography>
                        <div >
                            <GoogleLoginButton text={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].googleRegisterButton} onClick={handleGoogleRegister} style={{marginBottom: "10px"}}/>
                            <FacebookLoginButton onClick={handleFacebookRegister} style={{marginBottom: "10px", display: "none"}}/>
                            <div>
                            <Link

                                variant="body2"
                                onClick={() => {
                                    setPrivacyPolicyOpen(true);
                                }}
                            >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].privacyPolicyLinkText}</Link> | <Link
                            variant="body2"
                            onClick={() => {
                                setTermsOfServiceOpen(true);

                            }}
                        >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].termsOfServiceLinkText}</Link>
                            </div>


                                <span style={{ fontSize: '.7rem' }}>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].agreementStatement}</span>

                        </div>
                        <Divider>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].orText}</Divider>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].email}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={emailError}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="displayName"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].displayName}
                                id="displayName"
                                autoComplete="off"
                                error={displayNameError}
                                helperText={(displayNameError ? " The selected display name is not available." : "")}
                                onChange={(e) => {
                                    setDisplayNameValue(false)
                                    let newDisplayName = e.target.value.toLowerCase()
                                        .replace(/ /g, '-')
                                        .replace(/[^\w-]+/g, '');
                                    setDisplayNameValue(newDisplayName)
                                    confirmAvailability(newDisplayName)}
                                }
                            />

                            <Tooltip placement="top" style={{whiteSpace: "pre-line", display: "none"}}
                                title={<div style={{ whiteSpace: 'pre-line' }}>This will be the part of your API URLs that distinguishes you from others.<br />
                                    Example: https://appapi.hexplo.com/simple_api/*yourhandle*/bestapi - Just the part between the stars.</div>}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={handleValue || ""}
                                name="handle"
                                label="Handle"
                                id="handle"

                                error={handleError}
                                helperText={(handleError ? " The selected handle is taken." : "")}
                                onChange={(e) => {
                                    let newHandle = e.target.value.toLowerCase()
                                        .replace(/ /g, '-')
                                        .replace(/[^\w-]+/g, '');
                                    setHandleValue(newHandle)
                                    confirmAvailability(newHandle)}
                                }
                                autoComplete="off"
                            />
                            </Tooltip>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].password}
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                error={passwordError}
                                autoComplete="off"

                                InputProps ={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end"> {
                                                showPassword ? (
                                                    <VisibilityIcon className="cursor_pointer"
                                                                    onClick={
                                                                        handleClickShowPassword
                                                                    }
                                                    />
                                                ) : (
                                                    <VisibilityOff onClick={
                                                        handleClickShowPassword
                                                    }
                                                    />
                                                )
                                            }
                                            </InputAdornment>
                                        ),
                                    }
                                }

                            />

                            <div>
                            <Link

                                variant="body2"
                                onClick={() => {
                                    setPrivacyPolicyOpen(true);
                                }}
                            >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].privacyPolicyLinkText}</Link> | <Link
                            variant="body2"
                            onClick={() => {
                                setTermsOfServiceOpen(true);

                            }}
                        >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].termsOfServiceLinkText}</Link>
                            </div>
                            <span style={{ fontSize: '.7rem' }}>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].agreementStatement}</span>

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 1, backgroundColor: "#424242", textTransform: 'none' }}
                            >
                                {content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].registerButton}
                            </Button>
                            <Grid container>
                            </Grid>
                        </Box>
                    </div>
                    <div style={{width:"100%", height: "200px", display: registrationComplete ? "block" : "none"}} >
                        {content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].registrationSuccess}
                        <br /><br />
                        <MuiOtpInput value={otp} length={6} onChange={handleOtpChange} />
                        <br />
                        {otp.length === 6 ?
                            <Button variant={"contained"} size="small" onClick={validateAccount}
                                    style={{backgroundColor: "#424242", textTransform: 'none'}}>Validate Account</Button>
                            : null
                        }

                    </div>

                </Box>
            </Container>

            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={ privacyPolicyOpen }
                onClose={handlePPClose}

            >
                <DialogAppBar title="Privacy Policy" close={handlePPClose} />

                <Box sx={{padding: 2}}>
                    <PrivacyPolicy />
                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={handlePPClose}>Close</Button>
                    </div>
                </Box>
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={ termsOfServiceOpen }
                onClose={handleTOSClose}

            >
                <DialogAppBar title="Terms of Service" close={handleTOSClose} />
                <Box sx={{padding: 2}}>

                    <TermsOfService />
                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={handleTOSClose}>Close</Button>
                    </div>
                </Box>
            </Dialog>

        </ThemeProvider>
    );
}
