import React, {Component, useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";

import LanguageSelector from "../embeddedSelectors/languageSelector";
import {req_join_session, req_update_language} from "../../helpers/requests";
import {

    createChatSessionObject,

} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";


export default function ChangeChatLanguage (props) {

    const [open, setOpen] = React.useState(false);
    const [conversationType, setConversationType] = React.useState("join");
    const [language, setLanguage] = React.useState("");
    const [changeLanguage, setChangeLanguage] = React.useState("");
    const [changeSecondaryLanguage, setChangeSecondaryLanguage] = React.useState("");
    const [secondaryLanguage, setSecondaryLanguage] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [changeLanguageOpen, setChangeLanguageOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        props.close()
    }


    useEffect(() => {
        eventBus.on("updateLanguages", (uObj) => {
            //console.log("bus update sessions", props)
            getLanguageFromSession(uObj.sessionObj);
        });
        eventBus.remove("updateLanguages")
        getLanguageFromSession(JSON.parse(localStorage.getItem("cSession")))

    }, []);

    useEffect(() => {
        //console.log("ChangeChatLanguage", props)

        setLanguage(props.myLanguage || "en-US");

    }, [props.myLanguage, props.sessionId, props.secondaryLanguage])



    const getLanguageFromSession = (sessionObj) => {

        //console.log("getLanguageFromSession", sessionObj)

        setLanguage("");
        setSecondaryLanguage("");

        let session = JSON.parse(localStorage.getItem("cSession")) || {};

        if (session.id === localStorage.getItem("cSessionId")) {
            session.sessionMembers.forEach((member) => {

                if (member.userId === localStorage.getItem("uid")){
                    //self.setState({language: member.language, changeLanguage: member.language});
                    setLanguage(member.language);
                    setChangeLanguage(member.language);
                    if (session.sessionType === "shared")
                    {
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                //self.setState({secondaryLanguage: lang, changeSecondaryLanguage: lang});
                                setSecondaryLanguage(lang);
                                setChangeSecondaryLanguage(lang);
                            }
                        })
                    }
                }
            })
        }
        //})


    }

    //const setConversationInvoke = (ctype) => { setConversationType(ctype) }

    const updLanguage = (lang) => { setChangeLanguage(lang); }

    const updSecondaryLanguage = (lang) => { setChangeSecondaryLanguage(lang); }


    const updateSession = () => {

        console.log(language, localStorage.getItem("cSessionId"))
        //let self = this;


        eventBus.dispatch("showLoader", {showLoader: true})

        if (props.sessionType !== "shared") {

            req_join_session(props.sessionCode, changeLanguage).then((response) => {
                console.log("req_join_session response", response);
                if (response.status === 200) {
                    //self.setState({language: this.state.changeLanguage, changeLanguageOpen: false})
                    setLanguage(changeLanguage);
                    setChangeLanguageOpen(false);
                    createChatSessionObject(props.sessionId);
                    handleClose();

                    eventBus.dispatch("showLoader", {showLoader: false})

                } else {
                    alert("Invalid session code", localStorage.getItem("cSessionId"));
                    eventBus.dispatch("showLoader", {showLoader: false})
                }
            })
        } else {
            console.log("shared update")
            eventBus.dispatch("showLoader", {showLoader: false})
            req_update_language(props.sessionCode, changeLanguage, changeSecondaryLanguage).then((response) => {
                console.log("req_update_language response", response);
                if (response.status === 200) {

                    setLanguage(changeLanguage);
                    setChangeLanguageOpen(false);
                    createChatSessionObject(props.sessionId);
                    handleClose();

                    eventBus.dispatch("showLoader", {showLoader: false})
                } else {
                    console.log("Invalid session code", localStorage.getItem("cSessionId"));
                    eventBus.dispatch("showLoader", {showLoader: false})
                }

            })
        }
    }



        return (
                <div className={"languageSelectorBox"} >

                    {props.sessionType !== "shared" ?
                        <>
                            {content.chatScreenContent[language || "en-US"].myLanguageTabLabel}: {language}
                        </> :
                        <>{language}, {secondaryLanguage}</>
                    }



                    <IconButton onClick={(e) => {
                        //this.setState({anchorEl: e.currentTarget, changeLanguageOpen: true})
                        setAnchorEl(e.currentTarget);
                        setChangeLanguageOpen(true);
                        //setAnchorEl(e.currentTarget);
                        //setChangeLanguageOpen(true);

                    }}>
                        <EditIcon style={{fontSize: "12px"}}/>
                    </IconButton>

                    <Popover id={"change-language-popover"}
                             open={changeLanguageOpen}
                             anchorEl={anchorEl}
                             anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: -210,
                             }}
                    >
                        <div style={{padding: "15px", border: "1px solid #ddd"}}>
                            <div style={{fontWeight: "bold"}}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</div>
                            <div style={{display: "flex", flexDirection: "column"}}>

                            <div style={{padding: "10px"}}>
                                <div>
                                    {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].description}
                                </div>

                                {conversationType ?
                                    <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                        <LanguageSelector language={changeLanguage} setLanguage={updLanguage}
                                                          label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].languageSelectLabel} mutliSelect={false}/>
                                    </div>
                                    : null
                                }
                            </div>

                            {props.sessionType === "shared" ?
                                <div style={{padding: "10px"}}>
                                    <div>
                                        {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].secondaryDescription}
                                    </div>
                                    {conversationType ?
                                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                            <LanguageSelector language={changeSecondaryLanguage} setLanguage={updSecondaryLanguage}
                                                              label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].secondaryLanguageSelectLabel} mutliSelect={false}/>
                                        </div>
                                        : null
                                    }
                                </div>
                                    : null
                            }
                            </div>

                            <div style={{textAlign: "right"}}>
                                <Button variant={"outlined"} onClick={() => {setChangeLanguageOpen(false)}}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                                <Button style={{marginLeft: "10px"}} variant={"outlined"} onClick={updateSession}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                            </div>
                        </div>
                    </Popover>

                </div>

        );

}

