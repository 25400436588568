import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import Link from "@mui/material/Link";
import {req_delete_session} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import DialogAppBar from "./dialogAppBar";
import Box from "@mui/material/Box";
import PrivacyPolicy from "../content/privacyPolicy";
import TermsOfService from "../content/termsOfService";

export default class SiteInfo extends Component {

    state = {
        open: true,
        privacyPolicyOpen: false,
        termsOfServiceOpen: false

    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }


    handlePPClose = () => {
        this.setState({privacyPolicyOpen: false})
    }

    handleTOSClose = () => {
        this.setState({termsOfServiceOpen: false})
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
            >
                <DialogTitle>{content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>

                        {content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].ownership}<br /><br />

                        {content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].copyright}<br /><br />

                        {content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].infoBlurb}<br /><br />


                        <div style={{textAlign: "center"}}>
                        <Button variant={"contained"} onClick={() => {this.setState({privacyPolicyOpen: true})}}>{content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].privacyPolicyLinkText}</Button>
                        <Button style={{marginLeft: 10}} variant={"contained"} onClick={() => {this.setState({termsOfServiceOpen: true})}}>{content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].termsOfServiceLinkText}</Button>
                        </div>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.siteInfoPanel[localStorage.getItem("siteLanguage") || "en-US"].closeButtonText}</Button>

                </DialogActions>



                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={ this.state.privacyPolicyOpen }
                    onClose={this.handlePPClose}

                >
                    <DialogAppBar title="Privacy Policy" close={this.handlePPClose} />

                    <Box sx={{padding: 2}}>
                        <PrivacyPolicy />
                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handlePPClose}>Close</Button>
                        </div>
                    </Box>
                </Dialog>


                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={ this.state.termsOfServiceOpen }
                    onClose={this.handleTOSClose}

                >
                    <DialogAppBar title="Terms of Service" close={this.handleTOSClose} />
                    <Box sx={{padding: 2}}>

                        <TermsOfService />
                        <div align={"right"} style={{marginTop: "10px"}}>
                            <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={this.handleTOSClose}>Close</Button>
                        </div>
                    </Box>
                </Dialog>

            </Dialog>



        );
    }
}
