import React, {Component} from "react";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {content} from "../../globals/content";
import {req_put_delete_message, req_put_message_feedback} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";

export default class MessageOptions extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        chatSessionId: "",
        anchorEl: null,
        deleteMessageOpen: false,
    };

    deleteItem = () => {
        console.log("deleteItemOpen")
        this.setState({deleteMessageOpen: true})
    }

    deleteMessage = () => {
        console.log("deleteMessage")
        let self = this;
        let body = {
            sessionId: this.props.sessionId,
            messageId: this.props.messageId
        }

        eventBus.dispatch("showLoader", {showLoader: true})
        req_put_delete_message(body).then(
            (response) => {
                self.setState({})
                this.setState({deleteMessageOpen: false})
                //eventBus.dispatch("triggerChatRefresh", {})
                eventBus.dispatch("deleteMessage", {messageId: this.props.messageId})
                eventBus.dispatch("showLoader", {showLoader: false})
            }
        )
    }

    // Pin or Star
    render() {
        return (
            <div>
            <IconButton onClick={this.deleteItem}
                        size="small"
                        disableRipple
                        style={{padding: "0", color: "#4e6179", alignItems: "flex-start"}}
            >
            <DisabledByDefaultIcon style={{marginLeft: "5px", color: "#4e6179", fontSize: "20px"}} />
            </IconButton>


                <Dialog
                    open={this.state.deleteMessageOpen}
                    onClose={() => {
                        this.setState({deleteMessageOpen: false})
                    }}

                >
                    <DialogTitle>{content.messageOptionsWidget[localStorage.getItem("siteLanguage") || "en-US"].deleteTitle}</DialogTitle>
                    <DialogContent style={{padding: "10px"}}>
                        <DialogContentText>
                            {content.messageOptionsWidget[localStorage.getItem("siteLanguage") || "en-US"].deleteTextContent}
                        </DialogContentText>



                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} onClick={
                            () => {this.setState({
                                deleteMessageOpen: false})}
                        }>{content.messageOptionsWidget[localStorage.getItem("siteLanguage") || "en-US"].deleteCancelButtonText}</Button>
                        <Button variant={"outlined"}
                                onClick={this.deleteMessage}>
                            {content.messageOptionsWidget[localStorage.getItem("siteLanguage") || "en-US"].deleteDeleteButtonText}</Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}
