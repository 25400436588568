import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import {
    Button, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel, Radio,
    RadioGroup, Snackbar
} from "@mui/material";
import {content} from "../../globals/content";
import {sessionReset} from "../../helpers/session";
import {req_put_session_notification} from "../../helpers/requests";


export function NotificationCard(props) {

    const [approveOpen, setApproveOpen] = useState(false);
    const [responseOpen, setResponseOpen] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    const templateMap = {
        "join_request": content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].joinRequest,
        "invite": content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].invite,
        "invite_approved": content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].inviteApproved,
        "joined_announcement": content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].joinedAnnouncement
    }

    const openSession = (sessionId) => {
        console.log("Open session", sessionId);
        sessionReset(sessionId, true).then(() => {
            props.close()
        })
    }

    const approveSessionAccess = () => {
        console.log("approveSessionAccess")
        submitNotificationDecision("approve")
    }

    const rejectSessionAccess = () => {
        console.log("rejectSessionAccess")
        submitNotificationDecision("reject")
    }

    const submitNotificationDecision = (decision) => {
        console.log("submitNotificationDecision", decision, props)
        setResponseOpen(true)
        setButtonsDisabled(true)
        const body = {
            decision: decision,
            messageId: props.notificationObj.id,
        }

        req_put_session_notification(body).then((response) => {
            console.log("req_put_session_notification", response)

        })
    }

    const closeResponse = () => {
        setResponseOpen(false)
    }

    useEffect(() => {

    }, []);

    return (

        <Card key={"card_" + props.title}
              style={{minWidth: 280, flexGrow: 1, backgroundColor: "#f7f7f7", borderRadius: 5, padding: 5, paddingLeft: 15}}>

            {props.notificationObj.status === 0 &&
            <div style={{
                position: "absolute",
                left: 10,
                backgroundColor: "#7a99af",
                width: 10,
                borderRadius: 10,
                height: 10
            }}>&nbsp;</div>
            }



            <div style={{textAlign: "left", fontSize: ".7rem"}}>{new Date(props.notificationObj.created).toLocaleString('en-US')}</div>
            <div style={{textAlign: "left"}}>
                {["join_request", "joined_announcement"].includes( props.notificationType) ? props.notificationObj.payload.displayName : ""} {templateMap[props.notificationType]} <b>{props.notificationObj.payload.sessionName}</b>
            </div>



                { props.notificationType === "join_request" &&
                    (
                        <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}} >
                            <Button
                            style={{borderWidth: 1, borderColor: "#aaa", borderStyle: "solid",
                                borderTop: 0, borderRadius: 5, height: "25px"
                            }}
                            onClick={approveSessionAccess}
                            disabled={props.notificationObj.suppress_interaction === 1 || buttonsDisabled}
                            size={"small"}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].approveButton}</Button>

                            <Button
                            style={{borderWidth: 1, borderColor: "#aaa", borderStyle: "solid",
                                borderTop: 0, borderRadius: 5, height: "25px"
                            }}

                            disabled={props.notificationObj.suppress_interaction === 1 || buttonsDisabled}
                            onClick={rejectSessionAccess}

                            size={"small"}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].rejectButton}</Button>
                    </div>
                    )
                }

            { props.notificationType === "invite" &&
                (
                    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}} >
                        <Button
                            style={{borderWidth: 1, borderColor: "#ddd", borderStyle: "solid", color: "#fff",
                                borderTop: 0, borderRadius: 5, backgroundColor: "#7a99af", height: "25px"
                            }}
                            onClick={() => { props.setSessionType(props.sessionType) }}
                            contentStyle={{padding: 0, margin: 0}}
                            size={"small"}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].acceptButton}</Button>

                        <Button
                            style={{borderWidth: 1, borderColor: "#ddd", borderStyle: "solid", color: "#fff",
                                borderTop: 0, borderRadius: 5, backgroundColor: "#7a99af", height: "25px"
                            }}

                            onClick={() => { props.setSessionType(props.sessionType) }}
                            contentStyle={{padding: 0, margin: 0}}
                            size={"small"}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].rejectButton}</Button>
                    </div>
                )
            }

            { ["invite_approved", "joined_announcement"].includes( props.notificationType) &&
                (
                    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 10}} >
                        <Button
                            style={{borderWidth: 1, borderColor: "#ddd", borderStyle: "solid", color: "#fff",
                                borderTop: 0, borderRadius: 5, backgroundColor: "#7a99af", height: "25px"
                            }}
                            onClick={() => { openSession(props.notificationObj.payload.sessionId) }}
                            contentStyle={{padding: 0, margin: 0}}
                            size={"small"}>{content.notificationsPanel[localStorage.getItem("siteLanguage") || "en-US"].openConversationButton}</Button>


                    </div>
                )
            }


            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                autoHideDuration={4000}
                open={responseOpen}
                message="Response sent!"
                key={"topcenter"}
                onClose={closeResponse}
            />
        </Card>

    )





}
