import React, {Component} from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {content} from "../../globals/content";
import TableBody from "@mui/material/TableBody";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";


export default class DisplayNotifications extends Component {

    state = {
        open: true,
        loading: false,
        rows: []
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    render() {
        return (
            <div style={{ padding: "1px"
            }}>
                {this.state.rows.length > 0 ?
                    <TableContainer component={Paper}>
                        <Table sx={{ }} >
                            <TableHead>
                                <TableRow key={"tablehead"}>
                                    <TableCell component="th">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].nameHeader}</TableCell>
                                    <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].memberHeader}</TableCell>
                                    <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].languageHeader}</TableCell>

                                    <TableCell component="th"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            </TableBody>

                        </Table>
                    </TableContainer>
                    : <div>No notifications pending</div>}


            </div>
        );
    }

}
