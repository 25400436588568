import React, {Component} from 'react';
import Typography from "@mui/material/Typography";


export default class PrivacyPolicy extends Component {

    render() {
        return(

        <div style={{fontSize: ".9rem"}}>
            <Typography variant="h4" align={"left"} gutterBottom>
                Privacy Policy (Last updated: December 19, 2022)
            </Typography>

            <div style={{ margin: "10px"}}>
            <p style={{fontSize: ".9rem"}}>In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data.</p>
            <p style={{fontSize: ".9rem"}}>This policy applies to all products built and maintained by Earthlingual (owned by Hexplo, LLC).</p>
            </div>

            <div style={{ margin: "10px"}}>
            <h2 id="what-we-collect-and-why">What we collect and why</h2>
            <p style={{fontSize: ".9rem"}}>Our guiding principle is to collect only what we need. Here’s what that means in practice:</p>
                <div style={{marginTop: "10px"}}>
                    <h3 id="identity-access">Identity &amp; access</h3>
                    <p style={{fontSize: ".9rem"}}>When you sign up for Earthlingual product, we ask for identifying information such as your name, email address, and maybe a company name.
                        That’s so you can personalize your account, and we can send you product updates and other essential information.
                        We may also send you optional surveys from time to time to help us understand how you use our products and to make improvements.
                        With your consent, we will send you our newsletter and other updates. We may also give the option to add a profile picture or logo that displays in our products. We do not sell your personal information to third parties, and we won’t use your name or company in marketing statements without your permission.</p>
                </div>
                <div style={{marginTop: "10px"}}>
                    <h3 id="billing-information">Billing information</h3>
                    <p style={{fontSize: ".9rem"}}>If you sign up for a paid Earthlingual product, you will be asked to provide your payment information and billing address.
                        Credit card information is submitted directly to our payment processor and will not be retained on Earthlingual servers.
                        We will store a record of the payment transaction, including the last 4 digits of the credit card number, for purposes of account history, invoicing, and billing support.
                        We store your billing address so we can charge you for service, calculate any sales tax due, send you invoices, and detect fraudulent credit card transactions.
                        </p>
                </div>
                <div style={{marginTop: "10px"}}>
                    <h3 id="product-interactions">Product interactions</h3>
                    <p style={{fontSize: ".9rem"}}>We store on our cloud managed utilities the content that you upload or receive or maintain in your Earthlingual product accounts.
                        This is so you can use our products as intended. We keep this content as long as your account is active. If you delete your account, we’ll delete the content within 60 days. </p>
                </div>
                <div style={{marginTop: "10px"}}>
                    <h3 id="geolocation-data">Geolocation data</h3>
                    <p style={{fontSize: ".9rem"}}>For our products, we may log the full IP address used to sign up a product account and retain that for use in mitigating spam sign-ups. We also log all account access by full IP address for security and fraud prevention purposes.</p>
                </div>
                <div style={{marginTop: "10px"}}>
                    <h3 id="website-interactions">Website interactions</h3>
                    <p style={{fontSize: ".9rem"}}>We collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs.
                        This includes, for example, your browser and operating system versions, your IP address, which web pages you visited and how long they took to load, and which website referred you to us.
                        If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active.
                        The web analytics we use are described further in the Advertising and Cookies section.</p>
                </div>

                <div style={{marginTop: "10px"}}>
                    <h3 id="anti-bot-assessments">Anti-bot assessments</h3>
                    <p style={{fontSize: ".9rem"}}>We may use third-party <a href="https://en.wikipedia.org/wiki/CAPTCHA">CAPTCHA</a> services across our applications to mitigate brute force logins and as a means of spam protection.
                        We have a legitimate interest in protecting our apps and the broader Internet community from credential stuffing attacks and spam.
                        When you log into your Earthlingual account(s) and when you fill in certain forms, the CAPTCHA service evaluates various information (e.g., IP address, how long the visitor has been on the app, mouse movements) to try to detect if the activity is from an automated program instead of a human. We retain these data indefinitely for use in spam mitigation.</p>
                </div>
                <div style={{marginTop: "10px"}}>
                    <h3 id="advertising-and-cookies">Advertising and Cookies</h3>
                    <p style={{fontSize: ".9rem"}}>Earthlingual may run contextual ads on various third-party platforms such as Google, Reddit, Facebook, and LinkedIn.
                        Users who click on one of our ads will be sent to a Earthlingual marketing site. Where permissible under law, we may load an ad-company script on their browsers that sets a third-party cookie and sends information to the ad network to enable evaluation of the effectiveness of our ads, e.g., which ad they clicked and which keyword triggered the ad, and whether they performed certain actions such as clicking a button or submitting a form.</p>
                    <p style={{fontSize: ".9rem"}}>We also use persistent first-party cookies and some third-party cookies to store certain preferences, make it easier for you to use our applications, and perform A/B testing as well as support some analytics. </p>
                    <p style={{fontSize: ".9rem"}}>A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It might also collect information such as your browser type, operating system, web pages visited, duration of visit, content viewed, and other click-stream data. You can adjust cookie retention settings and accept or block individual cookies in your browser settings, although our apps won’t work and other aspects of our service may not function properly if you turn cookies off.</p>
                </div>

                <div style={{marginTop: "10px"}}>
                    <h3 id="voluntary-correspondence">Voluntary correspondence</h3>
                    <p style={{fontSize: ".9rem"}}>When you email Earthlingual with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.</p>
                    <p style={{fontSize: ".9rem"}}>We also store information you may volunteer, for example, written responses to surveys. If you agree to a customer interview, we may ask for your permission to record the conversation for future reference or use. We will only do so with your express consent.</p>
                </div>

                <div style={{marginTop: "10px"}}>
                    <h2 id="location-of-site-and-data">Location of site and data</h2>
                    <p style={{fontSize: ".9rem"}}>Our products and other web properties are operated in the United States. If you are located in the European Union, UK, or elsewhere outside of the United States, <strong>please be aware that any information you provide to us will be transferred to and stored in the United States</strong>. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.</p>
                </div>

                <div style={{marginTop: "10px"}}>
                    <h2 id="changes-questions">Changes &amp; Questions</h2>
                    <p style={{fontSize: ".9rem"}}>Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at <a href="mailto:info@hexplo.com">info@hexplo.com</a>.</p>
                </div>
            </div>
        </div>
        )
    }
}

