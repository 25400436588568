export const languageData = {

    "en-US": {
        direction: "lr",
        fontSideOverride: ""
    },
    "en-GB": {
        direction: "lr",
        fontSideOverride: ""
    },
    "es-ES": {
        direction: "lr",
        fontSideOverride: ""
    },
    "es-MX": {
        direction: "lr",
        fontSideOverride: ""
    },
    "fr-FR": {
        direction: "lr",
        fontSideOverride: ""
    },
    "de-DE": {
        direction: "lr",
        fontSideOverride: ""
    },
    "it-IT": {
        direction: "lr",
        fontSideOverride: ""
    },
    "hi-IN": {
        direction: "lr",
        fontSideOverride: ""
    },
    "cmn-CN": {
        direction: "lr",
        fontSideOverride: ""
    },
    "so-SO": {
        direction: "lr",
        fontSideOverride: ""
    },
    "pt-PT": {
        direction: "lr",
        fontSideOverride: ""
    },
    "pt-BR": {
        direction: "lr",
        fontSideOverride: ""
    },
    "id-ID": {
        direction: "lr",
        fontSideOverride: ""
    },
    "vi-VN": {
        direction: "lr",
        fontSideOverride: ""
    },
    "ko-KR": {
        direction: "lr",
        fontSideOverride: ""
    },
    "ja-JP": {
        direction: "lr",
        fontSideOverride: ""
    },
    "ar-IQ": {
        direction: "rl",
        fontSideOverride: ""
    },
    "fil-PH": {
        direction: "lr",
        fontSideOverride: ""
    },
    "ne-NP": {
        direction: "lr",
        fontSideOverride: ""
    },
    "ar-SA": {
        direction: "rl",
        fontSideOverride: ""
    },
    "am-ET": {
        direction: "lr",
        fontSideOverride: ""
    },
}
