import React, {Component} from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";

export default class DialogAppBar extends Component {
    render() {
        return (
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    {this.props.hideClose ?
                    null :
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.props.close}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    }

                    <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                        {this.props.title}
                    </Typography>
                </Toolbar>
            </AppBar>
        )
    }
}
