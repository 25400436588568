import React, {Component} from "react";
import {
    Button,
    IconButton,
    TextField
} from "@mui/material";

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Popover from '@mui/material/Popover';
import Box from "@mui/material/Box";
import {req_put_message_feedback} from "../../helpers/requests";
import {content} from "../../globals/content";
import eventBus from "../../helpers/eventBus";

export default class FeedbackButtons extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        chatSessionId: "",
        feedback: "",
        feedbackExplanationOpen: false,
        feedbackExplanation: "",
        anchorEl: null
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    openExplanation = (event) => {
        this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget})
    };

    toggleFeedback = (event, feedbackEval) => {

        if (this.state.feedback === feedbackEval) {
            this.setState({feedback: "", feedbackExplanationOpen: false,anchorEl: null})
        } else {
            this.setState({feedback: feedbackEval, feedbackExplanationOpen: true, anchorEl: this.state.anchorEl ? null : event.currentTarget})
        }
    }


    submitFeedback = () => {
        let self = this;

        let body = {
            feedback: this.state.feedback,
            feedbackExplanation: this.state.feedbackExplanation,
            sessionId: this.props.sessionId,
            messageId: this.props.messageId
        }
        eventBus.dispatch("showLoader", {showLoader: true})
        req_put_message_feedback(body).then(
            (response) => {

                self.setState({feedbackExplanationOpen: false, anchorEl: null})
                eventBus.dispatch("showLoader", {showLoader: false})

            }
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.feedback !== this.props.feedback) {
            this.setFeedbackState()
        }
    }

    setFeedbackState = () => {

        if (this.props.feedback && this.props.feedback !== undefined) {

            if (this.props.feedback.hasOwnProperty(localStorage.getItem("uid"))) {

                this.setState({
                    feedback: this.props.feedback[localStorage.getItem("uid")].feedback,
                    feedbackExplanation: this.props.feedback[localStorage.getItem("uid")].feedbackExplanation
                })
            }
        }
    }

    render() {
        //this.setFeedbackState();

        return (<div style={{display: "flex", flexDirection: "row", border: "1px dashed #ccc", padding: "3px", gap: "5px", height: "24px", borderTop: 0}}>

                <IconButton
                    style={{padding: 0}}
                    onClick={(e) => {
                    this.toggleFeedback(e, "positive")
                }}>
                    <ThumbUpIcon style={{
                        fontSize: "16px",
                        color: this.state.feedback === "positive" ? "#548554" : "#999"}} />
                </IconButton>
                <IconButton
                    style={{padding: 0}}
                    onClick={(e) => {
                    this.toggleFeedback(e, "negative")
                }}>
                    <ThumbDownIcon style={{
                        fontSize: "16px",
                        color: this.state.feedback === "negative" ? "#dd0000" : "#999"}} />

                </IconButton>

                <Popover id={"feedback-popover"}
                         open={this.state.feedbackExplanationOpen}
                         anchorEl={this.state.anchorEl}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'right',
                         }}
                >
                    <Box sx={{ border: "1px solid #ccc", backgroundColor: "#f9f9f9",
                        borderRadius: "5px", p: 1, zIndex: 5000 }}>
                        <ThumbUpIcon style={{
                            fontSize: "12px", color: "#aaa"}} />/<ThumbDownIcon style={{
                        fontSize: "12px", color: "#aaa"}} /> <b>{content.feedbackWidget[localStorage.getItem("siteLanguage") || "en-US"].reasonText}</b><br />
                        <TextField multiline style={{backgroundColor: "#fff"}}
                                   value={this.state.feedbackExplanation}
                                   onChange={(e) => {
                                       this.setState({feedbackExplanation: e.target.value})
                                   }}
                                   inputProps={{
                                       maxLength: 120,
                                   }}
                        >

                        </TextField ><br />
                        <div style={{display: "flex", justifyContent: "space-around", gap: 10}}>
                            <Button
                                size={"small"}
                                variant={"outlined"}
                                value={this.state.feedbackExplanation}

                                onClick={() => {
                                    this.submitFeedback()

                                }}
                            >{content.feedbackWidget[localStorage.getItem("siteLanguage") || "en-US"].submitButtonText}
                            </Button>
                            <Button
                                variant={"outlined"}
                                size={"small"}
                                onClick={() => {
                                    this.setState({
                                        feedbackExplanationOpen: false,
                                        anchorEl: null,
                                        feedback: this.props.feedback.hasOwnProperty(localStorage.getItem("uid")) ? this.props.feedback[localStorage.getItem("uid")].feedback : "",
                                        feedbackExplanation: this.props.feedbackExplanation.hasOwnProperty(localStorage.getItem("uid")) ? this.props.feedback[localStorage.getItem("uid")].feedbackExplanation : ""
                                    })
                                }}
                            >{content.feedbackWidget[localStorage.getItem("siteLanguage") || "en-US"].cancelButtonText}</Button>
                        </div>
                    </Box>
                </Popover>
            </div>

        );
    }
}
