import React, {Component} from "react";
import Typography from "@mui/material/Typography";
import {Button, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from 'uuid';
import Avatar from "@mui/material/Avatar";
import {content} from "../../globals/content";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import eventBus from "../../helpers/eventBus";
import {req_reset_password, req_user_account_get, req_user_settings_put} from "../../helpers/requests";
import {getAvatarUrlFromSettings, getColorFromSettings} from "../../helpers/settings";
import ImageCropper from "./imageCropper";
import {storageRef} from "../../helpers/firebase";
import DeleteAvatarImage from "./deleteAvatarImage";


export default class AccountPanel extends Component {



    state = {
        selectedAvatarColor: getColorFromSettings(),
        selectedAvatarUrl: getAvatarUrlFromSettings(),
        colors: ["#4444aa","#cc0000", "#ee4400", "#ccdd00", "#55aa55",
            "#4466aa", "#c62ed0", "#e64387", "#41a887"],
        imageCropperOpen: false,
        deleteImageOpen: false,
        showPasswordSuccess: false,
    }

    setSelectedColor = (color) => {

        this.setState({selectedAvatarColor: color});
        eventBus.dispatch("showLoader", {showLoader: true})

        req_user_settings_put(localStorage.getItem("uid"), {
            avatarColor: color
        }).then((response) => {
            req_user_account_get(localStorage.getItem("uid")).then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("settings", JSON.stringify(result.data.data.settings));
                    eventBus.dispatch("showLoader", {showLoader: false})
                    eventBus.dispatch("updateSessions", {});

                } else {
                    eventBus.dispatch("showLoader", {showLoader: false})
                }
            })
        })
    }

    resetData = () => {
        let self = this;
        eventBus.dispatch("showLoader", {showLoader: true})
        req_user_account_get(localStorage.getItem("uid")).then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("settings", JSON.stringify(result.data.data.settings));
                eventBus.dispatch("updateSessions", {});
                self.setState({selectedAvatarUrl: getAvatarUrlFromSettings()});
                eventBus.dispatch("showLoader", {showLoader: false})

            } else {
                eventBus.dispatch("showLoader", {showLoader: false})

            }
        })
    }

    openImageUploader = () => {
        console.log("open image uploader")
        this.setState({imageCropperOpen: true})
    }

    openDeleteImage = () => {
        this.setState({deleteImageOpen: true})
    }

    saveImage = (imageSrc) => {
        let self = this;
        eventBus.dispatch("showLoader", {showLoader: true})
        console.log("save image", imageSrc)
        let newImageId = uuidv4().toString();
        let newImageName = newImageId + '_img_pavatar.png';

        storageRef.child("avatars/" + newImageName).putString( imageSrc, 'data_url').then((snapshot) => {


            req_user_settings_put(localStorage.getItem("uid"), {
                avatarUrl: "avatars/" + newImageName
            }).then((response) => {
                req_user_account_get(localStorage.getItem("uid")).then(function (result) {
                    if (result.status === 200) {
                        localStorage.setItem("settings", JSON.stringify(result.data.data.settings));
                        self.setState({selectedAvatarUrl: getAvatarUrlFromSettings()});

                        eventBus.dispatch("updateSessions", {});
                        eventBus.dispatch("showLoader", {showLoader: false})

                    } else {
                        eventBus.dispatch("showLoader", {showLoader: false})
                    }
                })
            })

        });
        eventBus.dispatch("showLoader", {showLoader: false})

        //https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/avatars%2F7218a186-0a33-42d4-92cf-4238a58889ae_img_pavatar.png?alt=media
    }


    resetPassword = (event) => {
        event.preventDefault();
        let self = this;
        console.log("reset password")
        req_reset_password(localStorage.getItem("ownerEmail"))
            .then(function(result) {
                if (result.status === 200){

                    self.setState({showPasswordSuccess: true})

                    setTimeout(() => {
                        self.setState({showPasswordSuccess: true})
                    }, 5000);

                } else {
                    console.log("there was an error attempting to reset the password.")
                }
            })
        }


    render() {
        return (
            <div style={{padding: "10px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div><Typography variant="h5">{content.accountPanelContent[localStorage.getItem("siteLanguage") || "en-US"].title}</Typography></div><div>

                    <IconButton variant="contained" onClick={() => {
                        this.props.toggleAccountDrawer("right", false)
                    }
                    }>
                        <CloseIcon />
                    </IconButton>
                </div>
                </div>

                <div style={{display: "flex", justifyContent: "space-between", marginTop: 10, borderBottom: "1px solid #eee", paddingBottom: "10px"}}>
                    <div style={{width: "48%"}}><Avatar
                        key={"user_avatar"}
                        sx={{
                            bgcolor: this.state.selectedAvatarColor ? this.state.selectedAvatarColor : "#eee",
                            width: 100,
                            height: 100,
                            fontSize: ".8rem",
                            color: "#333",
                            margin: "auto"

                        }}
                        src={this.state.selectedAvatarUrl ? "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + this.state.selectedAvatarUrl.replace("/", "%2F") + "?alt=media" : null}
                    >&nbsp;</Avatar>
                        <div style={{textAlign: "center"}}>
                        <IconButton size={"small"} onClick={this.openImageUploader}>
                            <AddAPhotoIcon />
                        </IconButton>
                            {this.state.selectedAvatarUrl ?
                                <IconButton size={"small"} onClick={this.openDeleteImage}>
                                    <DeleteForeverIcon/>
                                </IconButton> : null
                            }

                        </div>
                    </div>

                    <div style={{width: "48%"}}>
                        <Typography variant="h6" style={{textAlign: "left", alignSelf: "center"}} display="inline">{localStorage.getItem("displayName")}</Typography>
                    </div>
                </div>
                <div style={{margin: "10px auto", borderBottom: "1px solid #eee", paddingBottom: "10px", display: "flex", gap: "5px", justifyContent: "center"}}>

                    {this.state.colors.map((color) => {
                        return <Avatar onClick={() => {
                            this.setSelectedColor(color)
                        }} sx={{
                            bgcolor: color,
                            width: this.state.selectedAvatarColor === color ? 28 : 24,
                            height: this.state.selectedAvatarColor === color ? 28 : 24,
                            border: this.state.selectedAvatarColor === color ? "3px solid #888" : "none",
                        }}>&nbsp;</Avatar>

                    })
                    }

                </div>

                <div style={{marginTop: 10}}><b>{content.accountPanelContent[localStorage.getItem("siteLanguage") || "en-US"].email}:</b> {localStorage.getItem("ownerEmail")}</div>
                <div style={{marginTop: 10, display: "none"}} ><Typography variant="h6" display="inline">Created:</Typography> {localStorage.getItem("displayName")}</div>



                <div style={{marginTop: 20, borderTop: "1px solid #eee", paddingTop: "10px", textAlign: "center"}}>
                    <Button onClick={this.resetPassword} variant={"outlined"}>Change Password</Button>
                </div>

                {this.state.imageCropperOpen ?
                    <ImageCropper open={this.state.imageCropperOpen}
                                  save={this.saveImage}
                                  resetData={this.resetData}
                                  close={() => {this.setState({imageCropperOpen: false})}} />
                    : null
                }

                {this.state.deleteImageOpen ?
                    <DeleteAvatarImage open={this.state.deleteImageOpen}
                                       resetData={this.resetData}
                                       close={() => {this.setState({deleteImageOpen: false})}} />
                    : null
                }

            </div>
        )
    }
}
