import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {
    getListeningState,
    getOtherInvokerState, setListeningState,
    setOtherInvokerState,
    setSuppressRestartState
} from "../../helpers/session";
import HearingIcon from "@mui/icons-material/Hearing";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import eventBus from "../../helpers/eventBus";
import Avatar from "@mui/material/Avatar";
import {CircleFlag} from "react-circle-flags";

export default function ChatSubmissionContainer (props) {


    const [language, setLanguage] = useState("")
    const [otherLanguage, setOtherLanguage] = useState("")
    const [chatDetailObj, setChatDetailObj] = useState({})
    const [micContinuous, setMicContinuous] = useState(localStorage.getItem("micContinuous") === "true")
    const [listeningState, setListeningState] = useState(false)
    const [entryText, setEntryText] = useState("")
    const [otherEntryText, setOtherEntryText] = useState("")




    useEffect(() => {

        setLanguage("")
        setChatDetailObj(props.chatDetailObj || {})

        eventBus.on("updateSubmissionContainer", (uObj) => {
            updateSessionData(uObj);
        });
        eventBus.remove("updateSubmissionContainer");

        eventBus.on("updateListeningState", (uObj) => {
            setListeningState(uObj.isListening);
        });
        eventBus.remove("updateListeningState");

        eventBus.on("clearEntryText", (uObj) => {
            clearEntryText();
        });
        eventBus.remove("clearEntryText");

        //this.updateSessionData();
    }, [props.chatDetailObj])

    const updateSessionData = (sessionObj) => {

        let session = localStorage.getItem("cSession") ? JSON.parse(localStorage.getItem("cSession")) : {};

        setChatDetailObj({...session})
        setMicContinuous(localStorage.getItem("micContinuous") === "true")

        if (session.id === localStorage.getItem("cSessionId")) {
            session.sessionMembers.forEach((member) => {
                if (member.userId === localStorage.getItem("uid")){
                    setLanguage(member.language)
                    if (session.sessionType === "shared")
                    {
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                setOtherLanguage(lang);
                            }
                        })
                    }
                }
            })
        }
    }

    useEffect(() => {
        console.log("Change in child detected, rendering new data", props.entryText, props.otherEntryText);
        setEntryText(props.entryText);
        setOtherEntryText(props.otherEntryText);
    }, [props.entryText, props.otherEntryText]);


    const clearEntryText = () => {
        setEntryText("");
        setOtherEntryText("");
    }


    let mainLanguage = language || "en-US";
    return (
        <div className={"submissionsContainer"} style={{
            marginLeft: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "0px" : 0,
            gap: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "10px" : 0

        }}>

            <div className={"submissionSubcontainer"}  style={{width: "100%"}}>

                <TextField value={entryText}
                           key="t2"
                           fullWidth
                           disabled={listeningState}
                           onChange={(e) => {
                               props.setQuery(e)
                           }}
                           multiline
                           inputProps={{
                               maxLength: 300,

                               bgcolor: listeningState ? "#eee" : "#fff",
                           }}
                           style={{ borderColor: "#ddeeff", backgroundColor: listeningState ? "#eee" : "#fff",
                               marginRight: "5px"
                           }}
                           sx={{bgcolor: "red"}}
                           onKeyDown={(e) => {
                               props.onEnterPress(e)
                           }}>
                </TextField>

                <div>

                    <div style={{fontSize: ".7rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 3}}><CircleFlag countryCode={(mainLanguage.split("-")[1]).toLowerCase()} height="14" style={{marginLeft: 5}} />({mainLanguage.split("-")[0]})</div>
                    <div style={{textAlign:"left", display: "flex", flexWrap: "none", marginLeft: 10}}>

                    <Avatar
                        sx={{ bgcolor: "#777", height: '32px', width: '32px' }}
                        style={{
                            marginLeft: "5px",
                            right: "8px",
                            color: "#fff"
                        }}
                        onClick={(e) => {
                            props.sendMessage(e)
                        }}
                    ><SendIcon/></Avatar>

                        <Avatar sx={{ bgcolor: listeningState && !getOtherInvokerState() ? "#80b33c" : "#777", height: '32px', width: '32px' }}
                            onClick={listeningState ?
                                () => {
                                    setSuppressRestartState(true);
                                    setListeningState(false);
                                    props.updRecordingState(false)
                                    props.turnOffMic();

                                } : () => {

                                    setOtherInvokerState(false);
                                    props.turnOffMic();
                                    setListeningState(true);

                                    props.updRecordingState(true)
                                    if (localStorage.getItem("micContinuous") === "true"){
                                        setSuppressRestartState(false);
                                    } else {
                                        setSuppressRestartState(true);
                                    }

                                    props.setSpeakingLanguage(language);
                                    props.turnOnMic(language);

                                }}>
                            <KeyboardVoiceIcon />
                        </Avatar>

                </div>
                </div>
            </div>

            {chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ?
                <div className={"submissionSubcontainer"} style={{width: "100%", marginLeft: 10}}>

                    <TextField value={otherEntryText}
                               key="t1"
                               fullWidth
                               disabled={listeningState}
                               onChange={(e) => {
                                   props.setOtherQuery(e)
                               }}
                               multiline
                               inputProps={{ maxLength: 300,
                                   backgroundColor: listeningState ? "#eee" : "#fff"
                                }}
                               style={{ borderColor: "#ddeeff", backgroundColor: listeningState ? "#eee" : "#fff",
                                   marginRight: "5px"}}>
                               onKeyDown={(e) => {
                                   props.onOtherEnterPress(e)
                               }}>
                    </TextField>

                    <div>
                        <div style={{fontSize: ".7rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 3}}><CircleFlag countryCode={otherLanguage.split("-")[1].toLowerCase()} height="14" style={{marginLeft: 5}} />({otherLanguage.split("-")[0]})</div>
                        <div style={{textAlign:"left", display: "flex", flexWrap: "none", marginLeft: 10}}>

                            <Avatar
                                    sx={{ bgcolor: "#777", height: '32px', width: '32px' }}
                                    style={{
                                        marginLeft: "5px",
                                        right: "8px",
                                        color: "#fff"
                                        }}
                                        onClick={(e) => {
                                            props.sendOtherMessage(e)
                                        }}
                            ><SendIcon/></Avatar>

                            <Avatar sx={{ bgcolor: listeningState && getOtherInvokerState() ? "#80b33c" : "#777", height: '32px', width: '32px' }}
                                    onClick={getListeningState() ?
                                        () => {
                                            setSuppressRestartState(true);
                                            props.updRecordingState(false)
                                            setListeningState(false)
                                            setOtherInvokerState(false);
                                            props.turnOffMic();
                                        } : () => {

                                            setListeningState(true);
                                            if (localStorage.getItem("micContinuous") === "true"){
                                                setSuppressRestartState(false);
                                            } else {
                                                setSuppressRestartState(true);
                                            }
                                            setListeningState(true)
                                            setOtherInvokerState(true);
                                            props.updRecordingState(true);
                                            props.setSpeakingLanguage(otherLanguage);
                                            props.turnOffMic();
                                            props.turnOnMic(otherLanguage);

                                        }
                                    }
                            >
                                <KeyboardVoiceIcon />
                            </Avatar>


                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    );

}

