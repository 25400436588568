
import {
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    MinusIcon,
    PlusIcon
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {IconButton} from "@mui/material";

export const ZoomSlider = (props) => {


    return (
        <div className={classNames("", 'flex items-center justify-center gap-2')}>
            <IconButton className="p-1" onClick={props.handleZoomOut}>
                <ZoomOutIcon />
            </IconButton>
            <input
                type="range"
                name="volju"
                min={props.min_zoom}
                max={props.max_zoom}
                step={props.zoom_step}
                value={props.zoom}
                onChange={e => {
                    props.setZoom(Number(e.target.value));
                }}
            />
            <IconButton className="p-1" onClick={props.handleZoomIn}>
                <ZoomInIcon />
            </IconButton>
        </div>
    );
};

export const RotationSlider = (props) => {

    return (
        <div className={classNames("", 'flex items-center justify-center gap-2')}>
            <IconButton onClick={props.handleRotateAntiCw}>
                <RotateLeftIcon />
            </IconButton>
            <input
                type="range"
                name="volju"
                min={props.min_rotation}
                max={props.max_rotation}
                step={props.rotation_step}
                value={props.rotation}
                onChange={e => {
                    props.setRotation(Number(e.target.value));
                }}
            />
            <IconButton onClick={props.handleRotateCw}>
                <RotateRightIcon />
            </IconButton>
        </div>
    );
};
