import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";

import LanguageSelector from "../embeddedSelectors/languageSelector";
import { req_join_session } from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";


export default class JoinChat extends Component {

    state = {
        open: true,
        conversationType: "join",
        sessionCode: "",
        language: "",
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    componentDidMount() {
        this.setState({language: localStorage.getItem("siteLanguage") || "en-US"});
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }

    setPeerLanguage = (lang) => {
        this.setState({peerLanguage: lang});
    }

    updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
            this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language, {accountId: localStorage.getItem("selectedAccount")});
        } else {
            this.setState({chatSessionId: undefined});
        }
    }

    createSession = (vConversationType, vSessionCode, vLanguage) => {

        let self = this;
        if (this.state.conversationType === "join") {

            if (!this.state.sessionCode){
                alert("Please enter a session code", vSessionCode);
            }
            else if (!this.state.language){
                alert("Please select your language", this.state.language);
            }
            else {

                eventBus.dispatch("showLoader", {showLoader: true})
                req_join_session(this.state.sessionCode, this.state.language).then((response) => {
                    console.log("req_join_session response", response);
                    if (response.status === 200) {

                        self.setState({language: response.data.language})
                        createChatSession(response.data.newSession);
                        createChatSessionUser(response.data.userId);
                        createSelectedAccount(response.data.sessionAccount);
                        self.updateSession();
                        self.handleClose();
                        eventBus.dispatch("showLoader", {showLoader: false})

                    } else {
                        alert("Invalid session code", vSessionCode);
                        eventBus.dispatch("showLoader", {showLoader: false})
                    }
                })
            }
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>{content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>

                        {content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].description}
                    </DialogContentText>


                    {this.state.conversationType === "join" ?
                        <div style={{width: "100%", margin: "10px auto 5px auto"}}>

                            <div style={{textAlign: "center"}}>
                                <TextField value={this.state.sessionCode}
                                           required
                                           label={content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].sessionCodeLabel}
                                           onChange={(e) => {
                                               this.setState({sessionCode: e.target.value})
                                           }} style={{width: "300px"}}
                                >
                                </TextField>
                            </div>
                        </div>
                        : null
                    }

                    {this.state.conversationType ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                              label={content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].myLanguageLabel} mutliSelect={false}/>
                        </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button variant={"contained"} onClick={this.handleClose}>{content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                    <Button variant={"contained"} onClick={this.createSession}>{content.joinChatPanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
