import React, {Component} from "react";
import {content} from "../../globals/content";
import SessionCard from "./sessionCard";
import {Button} from "@mui/material";
import {loadSessions} from "../../helpers/session";
import {req_get_all_sessions} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import BackdropAppear from "../staticDisplays/backdropAppear";


export default class DashboardContent extends Component {

    state = {
        currentSessions: [],
        loading: false
    }

    componentDidMount() {
        //console.log("dashboard content mounted!!!!")
        this.loadSessionsFromLocalStorage()

        eventBus.on("updateDashboard", (uObj) => {
            this.loadSessionsFromLocalStorage()
        });

    }

    loadSessionsFromLocalStorage = () => {
        let sessions = JSON.parse(localStorage.getItem("chatSessions")) || [];
        this.setState({currentSessions: sessions})

        if (sessions.length === 0) {
            this.loadSessionsFromDatabase()
        }
    }

    loadSessionsFromDatabase = () => {
        let self = this;
        this.setState({loading: true})
        req_get_all_sessions()
            .then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                    let sessions = result.data.sessions || [];
                    self.setState({currentSessions: sessions, loading: false})

                }
            })
    }


    render() {
        return (
            <div style={{padding: 10, display: "flex", gap: 10, flexFlow: "wrap", justifyContent: "center"}}>
                <BackdropAppear loading={this.state.loading || false} />
                <div style={{width: "100%", textAlign: "center"}}>
                    <Button onClick={() => {this.props.setCreateChatOpen(true)}}>{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].createChatTitle}</Button>
                    <Button onClick={() => {this.props.setJoinChatOpen(true)}}>{content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].joinChatTitle}</Button>

                </div>

                <SessionCard
                    key={"card1"}
                    sessions={this.state.currentSessions} sessionType={"conversation"}
                    title={content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentGroupChatTitle}
                    toggleLeftDrawer={this.props.toggleLeftDrawer}
                    joinSession={this.props.joinSession}
                    openNew={this.props.setCreateChatOpen}
                />
                <SessionCard
                    key={"card2"}
                    sessions={this.state.currentSessions} sessionType={"translate"}
                    title={content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentTranslatorChatTitle}
                    toggleLeftDrawer={this.props.toggleLeftDrawer}
                    joinSession={this.props.joinSession}
                    openNew={this.props.setCreateChatOpen}
                />
                <SessionCard
                    key={"card3"}
                    sessions={this.state.currentSessions} sessionType={"shared"}
                    title={content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentSharedChatTitle}
                    toggleLeftDrawer={this.props.toggleLeftDrawer}
                    joinSession={this.props.joinSession}
                    openNew={this.props.setCreateChatOpen}
                />

                <SessionCard
                    key={"card4"}
                    sessions={this.state.currentSessions} sessionType={"quickConnect"}
                    title={content.dashboardCardContent[localStorage.getItem("siteLanguage") || "en-US"].recentQuickConnectsTitle}
                    toggleLeftDrawer={this.props.toggleLeftDrawer}
                    joinSession={this.props.joinSession}
                    openNew={this.props.setQuickConnectOpen}
                />
            </div>
        )
    }
}
