import React, { Component } from 'react';
import { IconButton } from '@mui/material';
//import VolumeUpIcon from '@mui/icons-material/VolumeUp';
//import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopIcon from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Avatar from '@mui/material/Avatar';
import FeedbackButtons from "../widgets/feedbackButtons";
import "./messageHistory.css"
import AudioPlayControl from "../widgets/audioPlayControl";
import MessageOptions from "../widgets/messageOptions";
import {languageData} from "../../globals/languageData";
import {CircleFlag} from "react-circle-flags";

export default class MessageHistory extends Component {
    state = {
        audioFiles: {},
        audioPending: [],
        activeAudio: false,
        currentAudioFile: "",
        currentAudioId: "",
        firstLoad: this.props.firstLoad,
        activeMessages: [],
        activeTranslations: {},
        translationTab: 0,
        activePanels: {}
    }


    generateAudio = (message, autoplay = false) => {



        let audioFiles = this.state.audioFiles;

        if (autoplay === true) {

            //console.log("in autoplay")


            if (!this.state.audioFiles.hasOwnProperty(message.messageId) && !this.state.audioPending.includes(message.messageId)) {
                let audioPending = this.state.audioPending;
                audioPending.push(message.messageId)
                let self = this;
                let audioUrl = "";


                if (["conversation", "quickConnect"].includes(this.props.detailObj.sessionType)) {
                    console.log("in convo quickCon")
                    if (message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0) {
                        message.translationResponse.forEach((translation) => {
                            if (translation.language === message.language) {
                                audioUrl = translation.audioUrl;
                            }
                        })
                    }
                }

                if (["translate", "shared"].includes(this.props.detailObj.sessionType)) {
                    console.log("translate", message)
                    if (this.props.detailObj.sessionType === "translate" && message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0) {

                        /*
                        message.translationResponse.forEach((translation) => {
                            if (translation.language !== message.language) {
                                audioUrl = translation.audioUrl;
                            }
                        })

                        */
                        if (message.translationResponse.length > 1) {
                            audioUrl = message.translationResponse[1].audioUrl;
                        }

                    } else if (this.props.detailObj.sessionType === "shared" && message.hasOwnProperty("translationResponse") && message.translationResponse.length > 0) {
                        message.translationResponse.forEach((translation) => {
                            if (translation.language !== message.language) {
                                audioUrl = translation.audioUrl;
                            }
                        })
                    }
                }

                console.log("audioUrl", audioUrl)

                if (audioUrl !== "") {

                    audioFiles[message.messageId] = {
                        messageId: message.messageId,
                        fileData: null,
                        text: message.message,
                        url: audioUrl
                    }
                    self.setState({audioFiles: {...audioFiles}})
                    if (autoplay) {
                        //console.log("autoplay")
                        self.playAudio(message.messageId, audioUrl)
                    }
                } else {
                    //console.log("in else")
                }
            }
        } else {
            //console.log("not autoplay")
        }
    }

    playAudio = (messageId, audioUrl = "") => {

        try {
            this.setState({
                currentAudioFile: audioUrl,
                currentAudioId: messageId + "_" + audioUrl,
                activeAudio: true
            })
            let audio = document.getElementById("audioPlayer");
            audio.src = audioUrl;
            //console.log("audio", audio)
            audio.muted = false;
            audio.autoPlay = true;
            audio.volume = 1;
            audio.play();

            audio.onEnded = () => {
                //console.log("audio ended");

            };
        } catch (e) {
            console.log("error", e)
        }
    }

    pauseAudio = (messageId) => {
        //console.log("pauseAudio", messageId)
        let audio = document.getElementById("audioPlayer");
        audio.pause();
        this.setState({activeAudio: false})
    }

    resetAudio = () => {
        //console.log("resetAudio")
        this.setState({activeAudio: false})
    }

    MessageBlock = (props) => {
        //console.log(this.props.detailObj)
        //console.log(props.message)
        if (props.message.hasOwnProperty("isDeleted") && props.message.isDeleted === true) {
            return null
        } else {
            if (props.message.sender === "user") {
                //return this.promptBubble(props.message)
                //return this.responseNull();
                return null
            } else if (props.message.sender === "userFunc" || props.message.sender === "sessionFunc") {
                return this.userFuncBubble(props.message)
            } else if (props.message.sender === "userFunc") {
                return this.userFuncBubble(props.message)
            } else if (["conversation", "quickConnect"].includes(this.props.detailObj.sessionType)) {


                this.generateAudio(props.message,
                    (props.index === (props.length - 1)
                        && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                        && (localStorage.getItem("cSessionUserId") !== props.message.senderId)
                        && !this.state.firstLoad
                        && (localStorage.getItem("uid") !== props.message.sender)
                    ))

                return this.compositeBubble(props.message)

            } else if (this.props.detailObj.sessionType === "translate") {

                this.generateAudio(props.message,
                    (props.index === (props.length - 1)
                        && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                        && !this.state.firstLoad
                    ))

                return this.compositeBubble(props.message)

            } else if (this.props.detailObj.sessionType === "shared") {

                this.generateAudio(props.message,
                    (props.index === (props.length - 1)
                        && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true)
                        && ((localStorage.getItem("cSessionUserId") !== props.message.senderId) || this.props.detailObj.sessionType !== "conversation")
                        && !this.state.firstLoad
                        && ((localStorage.getItem("uid") !== props.message.sender) || this.props.detailObj.sessionType === "shared")
                    ), (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true))

                return this.sharedBubble(props.message)

            } else {
                return this.responseBubble(props.message, props.index, props.length)
            }
        }
    }

    responseNull = () => {
        return (<div key={"null"} style={{display: "none"}}></div>)
    }


    activatePanel = (initialId, panelId) => {
        let activePanels = this.state.activePanels;
        activePanels[initialId] = {"active": panelId};
        this.setState({activePanels: activePanels})
    }

    responseBubble = (message, ind, len) => {
        //console.log("response message", message)
        //this.generateAudio(message, (ind === (len - 1)
        //    && (localStorage.getItem("autoplay") === "true" || localStorage.getItem("autoplay") === true) )
        //    && ((localStorage.getItem("cSessionUserId") !== message.senderId) || this.props.detailObj.sessionType !== "conversation")
        //    && !this.state.firstLoad)

        if (message.hasOwnProperty("message") && message.message !== "") {
            return (<div key={message.id}
                         style={{
                             padding: "5px",
                             borderRadius: "5px",
                             borderTopLeftRadius: "0",
                             borderTopRightRadius: "0",
                             textAlign: "left",
                             display: "flex",
                             flexDirection: "row",
                             border: "1px solid #ddd",
                             backgroundColor: "#f7f7f7",
                             marginBottom: "15px",
                             fontSize: ".8rem",
                             marginLeft: "10px",
                             marginRight: "5px",
                             color: "#494949"
                         }}
                         className={"chat chat-ai"}>
                <div style={{width: "50px", fontSize: ".7rem"}}>[{message.translatedTo || message.language}]</div>
                <div key={message.id} style={{width: "100%"}}
                     dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />').replaceAll("\n", '<br />')}}/>

                <div style={{borderRadius: "20px", width: "40px", textAlign: "right"}}>
                    {this.state.audioFiles.hasOwnProperty(message.messageId) ?
                        <div style={{textAlign: "right"}}>
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && !this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                   this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.pauseAudio(message.messageId + "_" + message.audioUrl);
                                }}>
                                    <StopIcon size={20}
                                              style={{fill: this.state.currentAudioId === message.messageId + "_" + message.audioUrl && this.state.activeAudio ? "green" : "inherit"}}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId !== message.messageId + "_" + message.audioUrl ?
                                <IconButton style={{padding: "2px"}} onClick={(e) => {
                                    this.playAudio(message.messageId, message.audioUrl);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> : null
                            }
                        </div>
                        : <CircularProgress size={20}/>
                    }
                </div>


            </div>)
        }
    }



    userFuncBubble = (message) => {
        return (<div className={"chat chat-human"} style={{padding: "3px", borderRadius: "10px", border: "1px dashed #aaa", color: "#555", marginLeft: "60px", marginRight: "60px"}}>
            <ChangeCircleIcon style={{fontSize: "20px", marginRight: "10px", marginTop: "5px"}} /><div style={{display: "inline-block", paddingTop: 0, marginBottom: "5px"}} key={message.id} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} /></div>)
    }


    setPrimaryBorderColor = (message) => {

        if (this.props.detailObj.sessionType === "shared" && message.language === message.translationResponse[0].language){
            return "#30afcd"
        }

        if (this.props.detailObj.sessionType === "shared" && message.language !== message.translationResponse[0].language){
            return "#e16a09"
        }


        if (this.props.detailObj.sessionType !== "shared" &&
            this.props.detailObj.hasOwnProperty("userProps") &&
            this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
            this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
            this.props.detailObj.userProps[message.senderId].avatarColor){
            return this.props.detailObj.userProps[message.senderId].avatarColor
        }




        return "#e16a09"

    }

    setPrimaryBgColor = (message) => {

        if (this.props.detailObj.sessionType === "shared" && message.language === message.translationResponse[0].language){
            return "#f5fdff"
        }
        if (this.props.detailObj.sessionType === "shared" && message.language !== message.translationResponse[0].language){
            return "#fffbf9"
        }
        if (this.props.detailObj.sessionType !== "shared"){
            return "#fcfcfc"
        }
        return "#fffbf9"
    }


    compositeBubble = (message) => {
        //console.log(message)
        //console.log("message", message.feedback)
        let primaryObject = {};
        let remainingObjectArray = [];

            primaryObject = {
                language: message.language,
                response: message.message,
                audioUrl: "",
                id: ""
            }
        const userRec = this.props.detailObj.sessionMembers.find((member) => member.userId === localStorage.getItem("uid"));
        let cloneMessage = {...message};
        if (message.hasOwnProperty("translationResponse") && Array.isArray(message.translationResponse)){

            let tResponse = message.translationResponse;



            tResponse.forEach((translation) => {
                //console.log("translation", translation)
                //console.log("primaryObject", primaryObject)
                if ( (translation.hasOwnProperty("language") && userRec.hasOwnProperty("language")) && translation.language === userRec.language ){
                    primaryObject = translation;
                }
            })

            tResponse.forEach((translation) => {
                if (translation.language === primaryObject.language) {
                    //console.log("2nd", translation)
                    primaryObject.audioUrl = translation.audioUrl;
                    primaryObject.id = translation.id;
                } else {
                    //console.log("3rd", translation)
                    remainingObjectArray.push(translation);
                }
            })

            if (primaryObject.hasOwnProperty("response")
                && primaryObject.response
                && primaryObject.response !== ""
                && primaryObject.response !== null) {
                primaryObject.response.normalize();
                primaryObject.response.replaceAll(/(\r\n|\r|\n)/g, '<br />')
            } else {
                primaryObject.response = "";
            }
        }


        //console.log(primaryObject)


        return (
            <div key={"container_" + message.id}>

                <div className={"compositePrimaryContainer"}

                style={{
                    borderColor: this.setPrimaryBorderColor(message),
                    backgroundColor: this.setPrimaryBgColor(message),
                }}
                >




                    <Avatar
                        sx={{
                            width: 26, height: 26,
                            position: "absolute", left: "-10px",
                            top: "5px", fontSize: ".8rem",
                            border: "1px solid #888",
                            backgroundColor:
                                this.props.detailObj.hasOwnProperty("userProps") &&
                                this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
                                this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
                                this.props.detailObj.userProps[message.senderId].avatarColor ?
                                    this.props.detailObj.userProps[message.senderId].avatarColor
                                    : "#5a596c"
                        }}

                        src={
                            this.props.detailObj.hasOwnProperty("userProps") &&
                            this.props.detailObj.userProps.hasOwnProperty(message.senderId) &&
                            this.props.detailObj.userProps[message.senderId].hasOwnProperty("avatarColor") &&
                            this.props.detailObj.userProps[message.senderId].avatarUrl ?
                                "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + this.props.detailObj.userProps[message.senderId].avatarUrl.replace("/", "%2F") + "?alt=media" : null}
                    >{message.senderDisplayName[0] || ""}</Avatar>


                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language].hasOwnProperty("direction") && languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row"}}>

                        <div style={{ display: "flex", padding: "20px 5px 15px 5px", width: "100%",
                            flexDirection: languageData.hasOwnProperty(primaryObject.language) &&
                                languageData[primaryObject.language].hasOwnProperty("direction") &&
                            languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row",
                            marginLeft: "15px", textAlign: "left"}}>

                            { primaryObject.language && primaryObject.language !== "" && languageData.hasOwnProperty(primaryObject.language) &&
                            languageData[primaryObject.language].hasOwnProperty("direction") && languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{
                                    minWidth: "70px",
                                    padding: "5px 0 0 20px",
                                    fontSize: ".7rem",
                                    display: "flex",
                                    textAlign: "right",
                                    alignItems: "center"
                                }}>({primaryObject.language.split("-")[0]})
                                    <CircleFlag countryCode={primaryObject.language.split("-")[1].toLowerCase()} height="18" style={{marginLeft: 5, marginTop: 8}} />
                                </div>
                                : <div style={{
                                    minWidth: "70px",
                                    padding: "5px 20px 0 0px",
                                    fontSize: ".7rem",
                                    display: "flex",
                                    alignItems: "center"
                                }}>

                                    { primaryObject.language && <CircleFlag countryCode={primaryObject.language.split("-")[1].toLowerCase()} height="18" style={{marginRight: 5, marginTop: 8}} /> }
                                    ({primaryObject.language.split("-")[0]})
                                </div>
                            }


                            {primaryObject.language && languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language].hasOwnProperty("direction") && languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{fontSize: "1rem", textAlign: "right"}}>
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"rl"}
                                    />{primaryObject.response}
                                </div> :
                                <div style={{fontSize: "1rem", textAlign: "left"}}>{primaryObject.response}
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"lr"}
                                    />
                                </div>
                            }
                        </div>

                        <div style={{position: "absolute", color: "#111", top: "0", padding: "0px 6px 2px 5px", fontSize: ".7rem", marginLeft: "20px"}}>
                            { (this.props.detailObj.sessionType !== "translate" ? message.senderDisplayName + ": " : "") + new Date(message.sent).toLocaleString()}
                        </div>

                        <div style={{display: "flex", alignItems: "flexStart", marginLeft: primaryObject.language && languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language].hasOwnProperty("direction") && languageData[primaryObject.language]["direction"] === "rl" ? "20px" : "0"}}>
                            <FeedbackButtons messageId={message.initialId} sessionId={this.props.detailObj.id}
                                             feedback={message.feedback || ""} feedbackExplanation={message.feedbackExplanation || ""} />
                            { message.senderId === localStorage.getItem("uid") ?
                            <MessageOptions messageId={message.initialId} sessionId={this.props.detailObj.id} />
                                : null
                            }
                        </div>
                    </div>
                </div>

                <div className={"compositeSecondaryContainer"} style={{
                    border: this.state.activePanels.hasOwnProperty(message.initialId) || this.props.detailObj.sessionType === "translate"  ? "1px solid #ddd" : "none",
                    backgroundColor: this.state.activePanels.hasOwnProperty(message.initialId) || this.props.detailObj.sessionType === "translate" ? "#f7f7f7" : "transparent",
                }}>

                {remainingObjectArray.map((translation, index) => {
                    return (<div key={"sub_" + translation.id} className={"translationContainer"} style={{
                            display: (userRec.language !== translation.language) && (this.props.detailObj.sessionType !== "translate" && this.props.detailObj.sessionType !== "shared") ? "inline-block" : "none",
                            backgroundColor: this.state.activePanels.hasOwnProperty(message.initialId) && this.state.activePanels[message.initialId].active === translation.id ? "#555533" : "#f7f7f7",
                            color: this.state.activePanels.hasOwnProperty(message.initialId) && this.state.activePanels[message.initialId].active === translation.id ? "#fff" : "inherit",

                        }}  index={translation.id} label={translation.language} onClick={() => {

                            this.activatePanel(message.initialId, translation.id)}
                        }>{translation.language}
                        {translation.language === message.language ? <b>*</b> : null}
                    </div>)
                    })

                }

                {this.props.detailObj.sessionType === "conversation" || this.props.detailObj.sessionType === "quickConnect" || this.props.detailObj.sessionType === "translate"  ?
                    <div style={{display: "block"}}>
                        {remainingObjectArray.map((translation, index) => {

                            return (<div key={translation.id} index={index}
                                         value={this.state.translationTab}
                                         className={"translationTab"}
                                         label={languageData}
                                         style={{
                                             display: (this.state.activePanels.hasOwnProperty(message.initialId) &&
                                             this.state.activePanels[message.initialId].active === translation.id) || this.props.detailObj.sessionType === "translate" ? "flex" : "none",
                                             flexDirection: languageData[translation.language || "en-US"]["direction"] === "rl" ? "row-reverse" : "row",
                                         }}>


                                {languageData[translation.language || "en-US"]["direction"] === "rl" ?
                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px",
                                        textAlign: "right"
                                    }}>
                                        {this.props.detailObj.sessionType === "translate" ?
                                            <div style={{width: "80px", padding: "5px 10px 0 10px", fontSize: ".7rem", display: "flex", alignItems: "center", textAlign: languageData[translation.language || "en-US"]["direction"] === "rl" ? "right" : "left"
                                            }}><CircleFlag countryCode={translation.hasOwnProperty("language") ? translation.language.split("-")[1].toLowerCase() : ""} height="18" style={{marginRight: 5, marginTop: 8}} />({translation.hasOwnProperty("language") ? translation.language.split("-")[0] || "en-US" : "--"})
                                            </div>
                                            : null
                                        }
                                        <AudioPlayControl
                                            currentAudioId={this.state.currentAudioId} messageObject={translation}
                                            activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                            playAudio={this.playAudio} direction={"rl"}
                                        />
                                        {translation.response} </div> :

                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px",
                                        display: "flex"
                                    }}><div style={{
                                        minWidth: "70px",
                                        padding: "5px 20px 0 0px",
                                        fontSize: ".7rem",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}><CircleFlag countryCode={translation.hasOwnProperty("language") ? translation.language.split("-")[1].toLowerCase() : ""} height="18" style={{marginRight: 5}} />({translation.hasOwnProperty("language") ? translation.language.split("-")[0] : "--"})</div>{translation.response} <AudioPlayControl
                                        currentAudioId={this.state.currentAudioId} messageObject={translation}
                                        activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                        playAudio={this.playAudio}  direction={"lr"}
                                    /></div>
                                }


                            </div>)
                        })}
                    </div> : null
                }

                </div>
            </div>)
    }




    sharedBubble = (message) => {

        let primaryObject = {};
        let remainingObjectArray = [];

        primaryObject = {
            language: message.language,
            response: message.message,
            audioUrl: "",
            id: ""
        }

        if (message.hasOwnProperty("translationResponse") && Array.isArray(message.translationResponse)){

            let tResponse = message.translationResponse;

            tResponse.forEach((translation) => {
                if (translation.language === message.language) {
                    primaryObject = translation;
                }
            })

            tResponse.forEach((translation) => {
                if (translation.language === primaryObject.language) {
                    primaryObject.audioUrl = translation.audioUrl;
                    primaryObject.id = translation.id;
                } else {
                    remainingObjectArray.push(translation);
                }
            })

            if (primaryObject.hasOwnProperty("response")
                && primaryObject.response
                && primaryObject.response !== ""
                && primaryObject.response !== null) {
                primaryObject.response.normalize();
                primaryObject.response.replaceAll(/(\r\n|\r|\n)/g, '<br />')
            } else {
                primaryObject.response = "";
            }
        }



        return (
            <div key={"container_" + message.id}>

                <div className={"compositePrimaryContainer"}
                     style={{
                         borderColor: this.setPrimaryBorderColor(message),
                         backgroundColor: this.setPrimaryBgColor(message),
                     }}
                >

                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row"}}>

                        <div style={{ display: "flex", padding: "20px 5px 15px 5px", width: "100%",
                            flexDirection: languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language]["direction"] === "rl" ? "row-reverse" : "row",
                            marginLeft: "15px", textAlign: "left"}}>

                            {languageData.hasOwnProperty(primaryObject.language) && languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{
                                    minWidth: "70px",
                                    padding: "5px 0 0 20px",
                                    fontSize: ".7rem",
                                    display: "flex",
                                    textAlign: "right",
                                    alignItems: "center"
                                }}>({primaryObject.language.split("-")[0]})

                                    {primaryObject.language && <CircleFlag countryCode={primaryObject.language.split("-")[1].toLowerCase()} height="18" style={{marginLeft: 5, marginTop: 8}} />
                                    }


                                </div>
                                : <div style={{
                                    minWidth: "70px",
                                    padding: "5px 20px 0 0px",
                                    fontSize: ".7rem",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    {primaryObject.language && <CircleFlag countryCode={primaryObject.language.split("-")[1].toLowerCase()} height="18" style={{marginRight: 5, marginTop: 8}} /> }
                                    ({primaryObject.language ? primaryObject.language.split("-")[0] : ""})
                                </div>
                            }


                            {primaryObject.language && languageData[primaryObject.language]["direction"] === "rl" ?
                                <div style={{fontSize: "1rem", textAlign: "right"}}>
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"rl"}
                                    />{primaryObject.response}
                                </div> :
                                <div style={{fontSize: "1rem", textAlign: "left"}}>{primaryObject.response}
                                    <AudioPlayControl currentAudioId={this.state.currentAudioId}
                                                      messageObject={primaryObject}
                                                      activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                                      playAudio={this.playAudio}
                                                      direction={"lr"}
                                    />
                                </div>
                            }
                        </div>

                        <div style={{position: "absolute", color: "#111", top: "0", padding: "0px 6px 2px 5px", fontSize: ".7rem", marginLeft: "20px"}}>
                            { ( this.props.detailObj.sessionType !== "shared" ? message.senderDisplayName + ": " : "") + new Date(message.sent).toLocaleString()}
                        </div>

                        <div style={{display: "flex", alignItems: "flexStart", marginLeft: primaryObject.language && languageData[primaryObject.language]["direction"] === "rl" ? "20px" : "0"}}>
                            <FeedbackButtons messageId={message.initialId} sessionId={this.props.detailObj.id}
                                             feedback={message.feedback || ""} feedbackExplanation={message.feedbackExplanation || ""} />
                            { message.senderId === localStorage.getItem("uid") ?
                                <MessageOptions messageId={message.initialId} sessionId={this.props.detailObj.id} />
                                : null
                            }
                        </div>
                    </div>
                </div>

                <div className={"compositeSecondaryContainer"} style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f7f7f7",
                }}>

                    <div style={{display: "block"}}>
                            <div key={remainingObjectArray[0].id}
                                         value={this.state.translationTab}
                                         className={"translationTab"}
                                         label={languageData}
                                         style={{
                                             display: "flex",
                                             flexDirection: remainingObjectArray.length > 0 &&
                                             remainingObjectArray[0].hasOwnProperty("language") &&
                                             languageData[remainingObjectArray[0]?.language || "en-US"].hasOwnProperty("direction") &&
                                             languageData[remainingObjectArray[0]?.language || "en-US"]["direction"] === "rl" ? "row-reverse" : "row",
                                         }}>

                                {remainingObjectArray[0].hasOwnProperty("language") && languageData[remainingObjectArray[0].language || "en-US"]["direction"] === "rl" ?
                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px",
                                        textAlign: "right"
                                    }}>
                                        <div style={{
                                            width: "80px",
                                            padding: "5px 10px 0 10px",
                                            fontSize: ".7rem",
                                            display: "flex",
                                            alignItems: "center",
                                            textAlign: languageData[remainingObjectArray[0].language || "en-US"]["direction"] === "rl" ? "right" : "left"
                                        }}><CircleFlag countryCode={remainingObjectArray[0].hasOwnProperty("language") ? remainingObjectArray[0].language.split("-")[1].toLowerCase() : ""} height="18" style={{marginRight: 5, marginTop: 8}} />({remainingObjectArray[0].hasOwnProperty("language") ? remainingObjectArray[0].language.split("-")[0] || "en-US" : "--"})</div>
                                        <AudioPlayControl
                                            currentAudioId={this.state.currentAudioId} messageObject={remainingObjectArray[0]}
                                            activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                            playAudio={this.playAudio} direction={"rl"}
                                        />
                                        {remainingObjectArray[0].response} </div> :

                                    <div style={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        paddingTop: "5px",
                                        display: "flex"
                                    }}><div style={{
                                        minWidth: "70px",
                                        padding: "5px 20px 0 0px",
                                        fontSize: ".7rem",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}><CircleFlag countryCode={remainingObjectArray[0].hasOwnProperty("language") ? remainingObjectArray[0].language.split("-")[1].toLowerCase() : ""} height="18" style={{marginRight: 5}} />({remainingObjectArray[0].hasOwnProperty("language") ? remainingObjectArray[0].language.split("-")[0] : "--"})</div>{remainingObjectArray[0].response} <AudioPlayControl
                                        currentAudioId={this.state.currentAudioId} messageObject={remainingObjectArray[0]}
                                        activeAudio={this.state.activeAudio} pauseAudio={this.pauseAudio}
                                        playAudio={this.playAudio}  direction={"lr"}
                                    /></div>
                                }


                            </div>

                    </div>

                </div>
            </div>)
    }









    UnsetFirstLoad = () => {

        let messageIds = [];
        let activeTranslationIds = []
        this.props.messages.forEach((message) => {
            if (message.sender === "user"){
                messageIds.push(message.messageId)
            }

            if (message.sender !== "user" && message.initialId) {

                if (!activeTranslationIds.hasOwnProperty(message.initialId)) {
                    activeTranslationIds[message.initialId] = {
                        "translations": message.translationResponse
                    };
                } else {
                    activeTranslationIds[message.initialId].translations = message.translationResponse
                }
            }
        })


        this.setState({activeMessages: messageIds, activeTranslations: activeTranslationIds, firstLoad: false})
        return (<></>)
    }

    render() {
        //console.log("messages", this.props.messages)
        return (<>

            <audio id="audioPlayer" controls autostart="0" onEnded={this.resetAudio} style={{width: 150, height: 25, display: "none"}}>
                {this.state.hasOwnProperty("currentAudioFile") && this.state.currentAudioFile !== "" ?
                  <source
                    src={this.state.currentAudioFile}
                    type="audio/mp3" />
                  : null
                }
            </audio>
            {this.props.messages.map((message, index) => {
                return <this.MessageBlock key={message.id} message={message} index={index} length={this.props.messages.length} />
            })}

            {this.state.firstLoad ? <this.UnsetFirstLoad /> : null}
        </>)
    }
}
