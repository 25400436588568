import EasyCropper from 'react-easy-crop';


const Cropper = (props) => {

    return (
        <EasyCropper
            image={props.image || undefined}
            crop={props.crop}
            zoom={props.zoom}
            rotation={props.rotation}
            cropShape="round"
            aspect={1}
            onCropChange={props.onCropChange }
            onCropComplete={props.onCropComplete}
            onZoomChange={props.setZoom}
            setZoom={props.setZoom}
            setRotation={props.setRotation}
            showGrid={true}
            cropSize={{ width: 185, height: 185 }}
            style={{
                containerStyle: {
                    height: 220,
                    width: 220,
                    top: 8,
                    bottom: 8,
                    left: 8,
                    right: 8
                }
            }}
        />
    );
};

export default Cropper;
